import styled from "styled-components"
import {
  breakpoint,
  elementSize,
  font,
  spacing,
  transition,
} from "../../styles/variables"

export const Styled = {
  FooterOuter: styled.footer`
    background-color: ${props => props.theme.gray1};
  `,
  FooterTop: styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${elementSize.contentWidth.page};
    padding: ${spacing.md} ${spacing.sm};

    > :not(:last-child) {
      margin-bottom: ${spacing.md};
    }

    @media screen and (min-width: ${breakpoint.md}) {
      flex-direction: row;
      padding: ${spacing.lg} ${spacing.xl};

      > :not(:last-child) {
        margin-bottom: 0;
        padding-right: ${spacing.xxl};
      }
    }

    @media screen and (min-width: ${parseInt(elementSize.contentWidth.page) +
      parseInt(spacing.xl) * 2}px) {
      margin: 0 auto;
      padding: ${spacing.lg} 0;
    }
  `,
  FooterBottom: styled.div`
    margin-bottom: ${elementSize.headerHeight.mobile};
    background-color: ${props => props.theme.gray2};

    @media screen and (min-width: ${breakpoint.lg}) {
      margin-bottom: initial;
    }
  `,
  InfoColumn: styled.div`
    max-width: 400px;
  `,
  LinkColumn: styled.div`
    p {
      margin-top: 0;
      margin-bottom: ${spacing.sm};
      font-family: ${font.family.headings};
      font-size: ${font.size.md};
      font-weight: ${font.weight.bold};
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      > :not(:last-child) {
        margin-bottom: ${spacing.xs};
      }

      li {
        display: flex;
        align-items: center;

        a {
          color: ${props => props.theme.text};

          &:hover {
            color: ${props => props.theme.accentHover};
          }
        }

        span {
          position: relative;
          color: ${props => props.theme.gray5};

          &.teaser {
            margin-left: ${spacing.xxs};
            padding-bottom: ${spacing.xxs};
            font-size: ${font.size.xxs};
            font-weight: ${font.weight.bold};
            color: ${props => props.theme.accent};
            white-space: nowrap;
          }
        }
      }
    }
  `,
  LogoFull: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.sm};

    span {
      font-family: ${font.family.headings};
      font-size: ${font.size.md};
      font-weight: ${font.weight.bold};
    }

    svg {
      margin-right: ${spacing.xxs};
    }
  `,
  ContactInfo: styled.a`
    display: inline-flex;
    align-items: center;
    color: ${props => props.theme.text};

    &:hover {
      color: ${props => props.theme.accentHover};

      svg {
        fill: ${props => props.theme.accentHover};
        transition: ${transition.fast};
      }
    }

    svg {
      height: 24px;
      margin-right: ${spacing.xxs};
      fill: ${props => props.theme.text};
    }
  `,
  Copyright: styled.div`
    max-width: ${elementSize.contentWidth.page};
    padding: ${spacing.md} ${spacing.sm};
    color: ${props => props.theme.text};

    @media screen and (min-width: ${breakpoint.md}) {
      padding: ${spacing.sm} ${spacing.xl};
    }

    @media screen and (min-width: ${parseInt(elementSize.contentWidth.page) +
      parseInt(spacing.xl) * 2}px) {
      margin: 0 auto;
      padding: ${spacing.sm} 0;
    }

    p {
      display: inline;
      margin: 0;
    }
  `,
  Trademark: styled.div`
    max-width: ${elementSize.contentWidth.page};
    padding: 0 ${spacing.sm} ${spacing.sm} ${spacing.sm};
    color: ${props => props.theme.text};

    @media screen and (min-width: ${breakpoint.md}) {
      padding: 0 ${spacing.xl} ${spacing.sm} ${spacing.xl};
    }

    @media screen and (min-width: ${parseInt(elementSize.contentWidth.page) +
      parseInt(spacing.xl) * 2}px) {
      margin: 0 auto;
      padding: 0 0 ${spacing.sm} 0;
    }

    p {
      display: inline;
      margin: 0;
      font-size: ${font.size.xxs};
    }
  `,
}
