import styled from "styled-components"
import {
  borderRadius,
  font,
  spacing,
  transition,
} from "../../styles/variables"

export const Styled = {
  Wrapper: styled.div``,
  Title: styled.button`
    display: flex;
    width: 100%;
    padding: ${spacing.xs};
    font-family: ${font.family.headings};
    font-size: ${font.size.md};
    font-weight: ${font.weight.bold};
    border-radius: ${borderRadius.sm};
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    svg {
      flex: none;
      margin-right: ${spacing.xs};
      transition: ${transition.slow};
      fill: ${props => props.theme.text};
    }

    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }
  `,
  Content: styled.div`
    margin-left: ${spacing.xl};

    p {
      margin-top: 0;
    }
  `,
}
