const linkResolver = doc => {
  const defaultLanguage = "en-us"

  switch (doc.type) {
    case "home":
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang.slice(0, 2)}`
    case "about":
    case "contact":
    case "page":
    case "product":
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : `/${doc.lang.slice(0, 2)}/${doc.uid}`
    default:
      return "/"
  }
}

module.exports = linkResolver
