import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import linkResolver from '../../utils/linkResolver'
import Icon from '../Icon'
import { Styled } from './style'

// Inserts soft hyphens before capital letters for automatic word wrapping
function insertSoftHyphens(oldText) {
  return oldText.replace(/([A-Z])/g, "\xAD$1").trim()
}

const Card = ({ data, linkText, staticText }) => {
  const title = data.card_title?.text || ""
  const subtitle = data.card_subtitle?.text || ""
  const content = data.card_content?.text || ""
  const icon = data.card_icon || ""
  const link = data.card_link || ""
  const productLinkText = linkText || "view product"
  const productStaticText = staticText || ""

  const InternalLinkCard = () => (
    <Link to={linkResolver(link)}>
      <Styled.Title>{icon ? insertSoftHyphens(title) : title}</Styled.Title>
      {icon && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      <Styled.Content>
        {icon && <Icon name={icon} />}
        <Styled.Link className="card-link">
          <span>{icon ? productLinkText : content}</span>
          <Icon name="Arrow Right" />
        </Styled.Link>
      </Styled.Content>
    </Link>
  )

  const ExternalLinkCard = () => (
    <a href={link.url}>
      <Styled.Title>{icon ? insertSoftHyphens(title) : title}</Styled.Title>
      {icon && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      <Styled.Content>
        {icon && <Icon name={icon} />}
        <Styled.Link className="card-link">
          <span>{icon ? productLinkText : content}</span>
          <Icon name="Arrow Right" />
        </Styled.Link>
      </Styled.Content>
    </a>
  )

  const StaticCard = () => (
    <Styled.InnerWrapper>
      <Styled.Title>{icon ? insertSoftHyphens(title) : title}</Styled.Title>
      {icon && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      <Styled.Content>
        {icon && <Icon name={icon} />}
        <Styled.Link>
          {icon && (
            <span className="card-static-text">{productStaticText}</span>
          )}
          {content && <span className="card-highlight-text">{content}</span>}
        </Styled.Link>
      </Styled.Content>
    </Styled.InnerWrapper>
  )

  if (!data) return null

  return (
    <Styled.Wrapper
      className={`${link && link.url ? "hover-scale" : ""}${
        icon ? " big" : ""
      }`}
      key={uuidv4()}
    >
      {link && link.link_type === "Document"
        ? InternalLinkCard()
        : link.url
        ? ExternalLinkCard()
        : StaticCard()}
    </Styled.Wrapper>
  )
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  linkText: PropTypes.string,
  staticText: PropTypes.string,
}

export default Card
