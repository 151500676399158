import React from "react"
import PropTypes from "prop-types"
import { Styled } from "./style"
import Icon from "../Icon"

const ThemeSwitch = ({ darkMode, toggleDarkMode }) => {
  if (darkMode === undefined || toggleDarkMode === undefined) return null
  return (
    <Styled.Button
      aria-label="Toggle Dark Mode"
      onClick={() => toggleDarkMode(!darkMode)}
    >
      {darkMode ? <Icon name="sun" /> : !darkMode ? <Icon name="moon" /> : null}
    </Styled.Button>
  )
}

ThemeSwitch.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
}

export default ThemeSwitch
