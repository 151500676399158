import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Styled } from "./style"
import linkResolver from "../../utils/linkResolver"

const Button = ({
  type,
  size,
  label,
  link,
  className,
  ariaLabel,
  additionalArgument,
}) => {
  if (!link || !label) return null

  switch (true) {
    case link.link_type === "Document":
      return (
        <Link
          to={linkResolver(link)}
          state={{ additionalArgument: additionalArgument }}
          aria-label={ariaLabel}
        >
          <Styled.Button
            className={`${type ? type : ""}${size ? ` ${size}` : ""} ${
              className || ""
            }`}
          >
            {label}
          </Styled.Button>
        </Link>
      )
    case type === "submit":
      return (
        <Styled.Button aria-label={ariaLabel} className={className || ""}>
          <input type="submit" value={label} />
        </Styled.Button>
      )
    default:
      return (
        <a href={link.url} aria-label={ariaLabel}>
          <Styled.Button
            className={`${type ? type : ""}${size ? ` ${size}` : ""} ${
              className || ""
            }`}
          >
            {label}
          </Styled.Button>
        </a>
      )
  }
}

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  additionalArgument: PropTypes.string,
}

export default Button
