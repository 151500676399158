import styled from "styled-components"
import {
  borderRadius,
  boxShadow,
  breakpoint,
  elementSize,
  font,
  spacing,
} from "../../styles/variables"

export const Styled = {
  Hero: styled.div`
    max-width: ${elementSize.contentWidth.page};
    margin: 0 auto;
    padding-top: ${spacing.md};
    text-align: center;

    @media screen and (min-width: ${breakpoint.lg}) {
      margin-bottom: ${spacing.xl};
      padding-top: ${spacing.xxl};
    }

    &.hero-image {
      min-height: 55vh;
      margin-bottom: ${spacing.xxl};
      text-align: left;

      @media screen and (min-width: ${breakpoint.lg}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${spacing.xl};
        justify-items: center;
        align-items: center;
        min-height: calc(100vh - ${elementSize.headerHeight.desktop});
        padding-top: 0;
      }
    }

    &.hero-cards {
      margin-bottom: ${spacing.xxl};

      @media screen and (min-width: ${breakpoint.lg}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - ${elementSize.headerHeight.desktop});
        padding-top: 0;
      }
    }
  `,
  Heading: styled.div`
    > :first-child {
      margin-bottom: ${spacing.md};
    }

    > h1 {
      @media screen and (max-width: ${breakpoint.lg}) {
        font-size: ${font.size.xl};
      }
    }

    > h2 {
      font-size: ${font.size.md};
    }

    &.hero-image {
      > :not(:last-child) {
        margin-bottom: ${spacing.md};

        @media screen and (min-width: ${breakpoint.lg}) {
          margin-bottom: ${spacing.xl};
        }
      }
    }

    .highlighted {
      color: ${props => props.theme.accent};
    }
  `,
  Icon: styled.div`
    svg {
      width: ${spacing.xl};
      height: ${spacing.xl};
      margin-bottom: ${spacing.xs};

      @media screen and (min-width: ${breakpoint.sm}) {
        width: ${spacing.xxl};
        height: ${spacing.xxl};
      }
    }
  `,
  Buttons: styled.div`
    @media screen and (min-width: ${breakpoint.sm}) {
      display: flex;
    }

    > :last-child:not(:first-child) {
      margin-left: ${spacing.md};
    }
  `,
  ImageWrapper: styled.div`
    margin-top: ${spacing.lg};

    @media screen and (min-width: ${breakpoint.lg}) {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 0;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: ${borderRadius.md};
      box-shadow: ${boxShadow};

      @media screen and (min-width: ${breakpoint.lg}) {
        position: absolute;
        top: 50%;
        left: 0;
        max-width: initial;
        max-height: 50vh;
        transform: translateY(-50%);
      }
    }
  `,
  Cards: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, ${elementSize.cardSize.sm});
    grid-column-gap: ${spacing.sm};
    grid-row-gap: ${spacing.sm};
    justify-content: center;
    padding-top: ${spacing.lg};

    @media screen and (min-width: ${breakpoint.sm}) {
      grid-template-columns: repeat(auto-fit, ${elementSize.cardSize.md});
      padding-top: ${spacing.xxl};
    }
  `,
  ScrollIndicator: styled.div`
    position: relative;
    display: none;
    margin-top: ${spacing.xl};

    @media screen and (min-width: ${breakpoint.lg}) {
      display: block;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      margin-top: ${spacing.xxl};
    }

    svg {
      position: absolute;
      left: 50%;
      width: 36px;
      height: 36px;
      fill: ${props => props.theme.gray4};
      transform: translateX(-50%);
      animation: MoveUpDown 2s ease infinite;
    }

    @keyframes MoveUpDown {
      0%,
      100% {
        transform: translateX(-50%) translateY(0);
      }
      50% {
        transform: translateX(-50%) translateY(-10px);
      }
    }
  `,
}
