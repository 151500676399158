import React from "react"
import PropTypes from "prop-types"
import { breakpoint } from "../../styles/variables"

const ResponsiveImage = ({ data }) => {
  if (!data) return null

  return (
    <picture>
      {data.thumbnails && (
        <source
          srcSet={data.thumbnails.mobile ? data.thumbnails.mobile.url : ""}
          alt={data.thumbnails.mobile ? data.thumbnails.mobile.alt : ""}
          media={`(max-width: ${breakpoint.sm})`}
        />
      )}

      <img src={data.url} alt={data.alt} />
    </picture>
  )
}

ResponsiveImage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ResponsiveImage
