import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { RichText } from "prismic-reactjs"
import CustomLink from "../../utils/customLink"
import { Styled } from "./style"
import ResponsiveImage from "../Responsive Image"
import Icon from "../Icon"

const ImageGallery = ({ data }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [galleryImageIndex, setGalleryImageIndex] = useState(0)
  const [lightroomImageIndex, setLightboxImageIndex] = useState(0)
  const [imageData, setImageData] = useState({})
  const [imageDescriptionData, setImageDescriptionData] = useState({})
  const imageWidth = 533
  const imageMargin = 32

  const closeOnEscape = useCallback(e => {
    if (e.keyCode === 27) {
      setLightboxOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", closeOnEscape, false)

    return () => {
      document.removeEventListener("keydown", closeOnEscape, false)
    }
  }, [closeOnEscape])

  // Show the second image on initial render
  useEffect(() => {
    if (data) {
      setGalleryImageIndex(1)
    }
  }, [data])

  useEffect(() => {
    if (lightboxOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "initial"
    }
  }, [lightboxOpen])

  if (!data) return null

  const setLightboxData = index => {
    setLightboxImageIndex(index)
    setImageData(data[index].image)
    setImageDescriptionData(data[index].description.raw)
    setLightboxOpen(true)
  }

  const Lightbox = () => (
    <Styled.LightboxWrapper>
      {lightroomImageIndex > 0 && (
        <Styled.NavigationButton
          onClick={() => setLightboxData(lightroomImageIndex - 1)}
          className="prev"
        >
          <Icon name="chevron left" />
        </Styled.NavigationButton>
      )}
      <Styled.Lightbox onClick={() => setLightboxOpen(false)}>
        <Styled.NavigationButton
          onClick={() => setLightboxOpen(false)}
          className="close"
        >
          <Icon name="cross" />
        </Styled.NavigationButton>
        <Styled.ImageFull>
          <ResponsiveImage data={imageData} />
          {Object.keys(imageDescriptionData).length > 0 && (
            <Styled.Description>
              <RichText
                render={imageDescriptionData || []}
                serializeHyperlink={CustomLink}
              />
            </Styled.Description>
          )}
        </Styled.ImageFull>
      </Styled.Lightbox>
      {lightroomImageIndex < Object.keys(data).length - 1 && (
        <Styled.NavigationButton
          onClick={() => setLightboxData(lightroomImageIndex + 1)}
          className="next"
        >
          <Icon name="chevron right" />
        </Styled.NavigationButton>
      )}
    </Styled.LightboxWrapper>
  )

  return (
    <>
      {lightboxOpen && Lightbox()}
      <Styled.Wrapper>
        {galleryImageIndex > 0 && (
          <Styled.NavigationButton
            onClick={() => setGalleryImageIndex(galleryImageIndex - 1)}
            className="prev gallery"
          >
            <Icon name="chevron left" />
          </Styled.NavigationButton>
        )}
        <Styled.ImageContainerWrapper
          className={`${galleryImageIndex > 0 ? "left-gradient" : ""} ${
            galleryImageIndex < Object.keys(data).length - 1
              ? "right-gradient"
              : ""
          }`}
        >
          <Styled.ImageContainer
            style={{
              transform: `translateX(-${
                galleryImageIndex > 0
                  ? imageWidth / 2 +
                    galleryImageIndex * (imageWidth + imageMargin)
                  : imageWidth / 2
              }px)`,
            }}
          >
            {data.map((item, index) => (
              <Styled.Image
                key={uuidv4()}
                onClick={() => setLightboxData(index)}
              >
                <ResponsiveImage data={item.image} />
              </Styled.Image>
            ))}
          </Styled.ImageContainer>
        </Styled.ImageContainerWrapper>
        {galleryImageIndex < Object.keys(data).length - 1 && (
          <Styled.NavigationButton
            onClick={() => setGalleryImageIndex(galleryImageIndex + 1)}
            className="next gallery"
          >
            <Icon name="chevron right" />
          </Styled.NavigationButton>
        )}
      </Styled.Wrapper>
    </>
  )
}

ImageGallery.propTypes = {
  data: PropTypes.array.isRequired,
}

export default ImageGallery
