import styled from "styled-components"
import {
  borderRadius,
  boxShadow,
  breakpoint,
  elementSize,
  font,
  spacing,
  transition,
} from "../../styles/variables"

export const Styled = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: ${elementSize.cardSize.sm};
    min-width: ${elementSize.cardSize.sm};
    height: ${elementSize.cardSize.sm};
    color: ${props => props.theme.text};
    text-align: left;
    background-color: ${props => props.theme.backgroundLight};
    border-radius: ${borderRadius.sm};
    box-shadow: ${boxShadow};
    transition: ${transition.fast};

    &:hover {
      .card-link {
        color: ${props => props.theme.accentHover};

        svg path {
          fill: ${props => props.theme.accentHover};
        }
      }
    }

    &.hover-scale:hover {
      transform: scale(1.02);
    }

    &.big {
      @media screen and (min-width: ${breakpoint.sm}) {
        width: ${elementSize.cardSize.md};
        min-width: ${elementSize.cardSize.md};
        height: ${elementSize.cardSize.md};
      }
    }

    > :first-child {
      display: inherit;
      flex-direction: inherit;
      justify-content: inherit;
      height: inherit;
      padding: ${spacing.sm};
      color: inherit;

      @media screen and (min-width: ${breakpoint.sm}) {
        padding: ${spacing.md};
      }
    }
  `,
  InnerWrapper: styled.div`
    width: 100%;
  `,
  Title: styled.p`
    margin: 0;
    font-family: ${font.family.headings};
    font-size: ${font.size.md};
    font-weight: ${font.weight.bold};
  `,
  Subtitle: styled.span`
    font-size: ${font.size.xxs};
    color: ${props => props.theme.gray5};

    @media screen and (min-width: ${breakpoint.sm}) {
      font-size: ${font.size.xs};
    }

    &.dark {
      color: ${props => props.theme.gray1};
    }
  `,
  Content: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    .card-static-text {
      color: ${props => props.theme.gray5};
    }

    .card-highlight-text {
      font-family: ${font.family.headings};
      font-size: ${font.size.lg};
      font-weight: ${font.weight.bold};
      color: ${props => props.theme.accent};
    }

    svg {
      width: 24px;
      height: 24px;

      @media screen and (min-width: ${breakpoint.sm}) {
        width: 48px;
        height: 48px;
      }
    }
  `,
  Link: styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.accent};
    transition: ${transition.fast};

    svg {
      width: 24px;
      height: 24px;
      fill: ${props => props.theme.accent};
      transition: ${transition.fast};
    }
  `,
}
