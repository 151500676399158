import styled from "styled-components"
import { spacing, transition } from "../../styles/variables"

export const Styled = {
  Button: styled.button`
    display: flex;
    align-items: center;
    padding: ${spacing.xxs};
    user-select: none;
    transition: ${transition.fast};

    &:hover {
      svg {
        fill: ${props => props.theme.accentHover};
      }
    }

    svg {
      fill: ${props => props.theme.text};
      transition: ${transition.fast};
    }
  `,
}
