import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Link as PrismicLink } from "prismic-reactjs"
import linkResolver from "./linkResolver"

const CustomLink = (type, element, content, children, index) => {
  if (element.data.link_type === "Document") {
    return (
      <Link to={linkResolver(element.data)} key={element.data.id}>
        {content}
      </Link>
    )
  }

  if (element.data.link_type === "Web") {
    if (
      element.data.url.includes("mailto:") ||
      element.data.url.includes("tel:")
    ) {
      return <a href={element.data.url}>{content}</a>
    }

    return (
      <PrismicLink key={element.data.id} to={linkResolver(element.data)}>
        <a href={linkResolver(element.data)}>{content}</a>
      </PrismicLink>
    )
  }
  return null
}

CustomLink.propTypes = {
  element: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
}

export default CustomLink
