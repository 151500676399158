import styled from "styled-components"
import {
  borderRadius,
  boxShadow,
  breakpoint,
  elementSize,
  spacing,
} from "../../styles/variables"

// TODO: Add custom bullet points with checkmark to list
export const Styled = {
  Wrapper: styled.section`
    position: relative;
    max-width: ${elementSize.contentWidth.text};
    margin: ${spacing.xxl} auto;

    &:first-of-type {
      margin-top: 0;
    }

    &.section-image {
      max-width: ${elementSize.contentWidth.page};
    }

    &.section-cards {
      max-width: ${elementSize.contentWidth.page};
      margin-top: 0;
    }

    &.section-cta {
      max-width: ${elementSize.contentWidth.page};
      margin-top: 0;
      margin-bottom: ${spacing.xxl};
      padding: ${spacing.md};
      overflow: hidden;
      color: ${props => props.theme.background};
      text-align: left;
      background-color: ${props => props.theme.accent};
      border-radius: ${borderRadius.md};

      @media screen and (min-width: ${breakpoint.md}) {
        padding: ${spacing.xl};
      }
    }

    .highlighted {
      color: ${props => props.theme.accent};
    }
  `,
  Content: styled.div`
    &.content-cta {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: ${breakpoint.md}) {
        flex-direction: row;
      }

      > * {
        position: relative;
        overflow: hidden;

        @media screen and (min-width: ${breakpoint.md}) {
          overflow: visible;
        }
      }
    }
  `,
  Title: styled.div`
    margin-bottom: ${spacing.sm};

    @media screen and (min-width: ${breakpoint.sm}) {
      margin-bottom: ${spacing.xl};
    }

    &.center {
      text-align: center;
    }

    > :first-child {
      margin-bottom: ${spacing.sm};
    }
  `,
  Row: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: ${breakpoint.sm}) {
      display: grid;
      grid-gap: ${spacing.md};
    }

    &.row-grid-2 {
      align-items: center;

      @media screen and (min-width: ${breakpoint.sm}) {
        grid-template-columns: 3fr 1fr;
        align-items: center;
      }
    }

    &.row-grid-3 {
      @media screen and (min-width: ${breakpoint.sm}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &.row-image {
      @media screen and (min-width: ${breakpoint.md}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${spacing.xl};
        justify-items: center;
        align-items: center;

        > * {
          margin: ${spacing.lg} 0;
        }
      }
    }

    ul {
      li:not(:last-of-type) {
        margin-bottom: ${spacing.xs};
      }
    }
  `,
  Column: styled.div`
    margin-top: ${spacing.md};

    @media screen and (min-width: ${breakpoint.md}) {
      margin-top: 0;
    }

    > :not(:last-child) {
      @media screen and (min-width: ${breakpoint.md}) {
        margin-bottom: ${spacing.sm};
      }
    }

    &.col-image {
      @media screen and (min-width: ${breakpoint.md}) {
        position: relative;
        width: 100%;
        min-height: 400px;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: ${borderRadius.md};
        box-shadow: ${boxShadow};

        @media screen and (min-width: ${breakpoint.md}) {
          position: absolute;
          top: 50%;
          right: 0;
          width: auto;
          height: 400px;
          transform: translateY(-50%);
        }
      }

      &-right {
        img {
          @media screen and (min-width: ${breakpoint.md}) {
            left: 0;
          }
        }
      }
    }

    &.col-cta {
      @media screen and (min-width: ${breakpoint.sm}) {
        flex: 50%;
      }
    }
  `,
  ImageWrapper: styled.div`
    display: grid;
    grid-gap: ${spacing.xs};
    justify-content: center;
    width: 100%;
    margin-top: ${spacing.md};

    @media screen and (min-width: ${breakpoint.md}) {
      position: absolute;
      right: -15%;
      z-index: 0;
      margin-top: 0;
      transform: rotate(15deg);
    }

    &.image-wrapper-1-child {
      @media screen and (min-width: ${breakpoint.md}) {
        top: 15%;
      }
    }

    &.image-wrapper-2-child {
      @media screen and (min-width: ${breakpoint.md}) {
        top: -15%;
      }
    }

    &.image-wrapper-3-child {
      @media screen and (min-width: ${breakpoint.md}) {
        top: -50%;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: ${borderRadius.md};
    }

    picture {
      &:not(:first-of-type) {
        display: none;

        @media screen and (min-width: ${breakpoint.md}) {
          display: initial;
        }
      }
    }
  `,
  Cards: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, ${elementSize.cardSize.sm});
    grid-column-gap: ${spacing.sm};
    grid-row-gap: ${spacing.sm};
    justify-content: center;
    margin-top: -${spacing.md};
    margin-bottom: -${spacing.md};
    padding-top: ${spacing.md};
    padding-bottom: ${spacing.md};
  `,
  Accordions: styled.div`
    > :not(:last-of-type) {
      margin-bottom: ${spacing.sm};
    }
  `,
  AccordionText: styled.div`
    margin-top: ${spacing.xl};
    margin-bottom: ${spacing.md};
    text-align: center;
  `,
  Button: styled.div`
    display: flex;
    margin-top: ${spacing.lg};

    &.center {
      justify-content: center;
    }

    a {
      width: 100%;

      @media screen and (min-width: ${breakpoint.sm}) {
        width: initial;
      }
    }
  `,
}
