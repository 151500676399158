import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Styled } from "./style"
import Footer from "../Footer"
import Header from "../Header"
import { AppContext } from "../../contexts/AppProvider"

const Layout = ({ children, location, activeDoc }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <AppContext.Consumer>
      {context =>
        isMounted && (
          <>
            <Header
              location={location}
              activeDoc={activeDoc}
              darkMode={context.darkMode}
              toggleDarkMode={context.setDarkMode}
            />
            <Styled.Main>{children}</Styled.Main>
            <Footer activeDoc={activeDoc} />
          </>
        )
      }
    </AppContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string,
  activeDoc: PropTypes.object,
}

export default Layout
