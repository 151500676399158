import styled from "styled-components"
import { breakpoint, elementSize, spacing } from "../../styles/variables"

export const Styled = {
  Main: styled.main`
    margin-top: ${elementSize.headerHeight.mobile};
    margin-right: auto;
    margin-left: auto;
    padding-right: ${spacing.sm};
    padding-left: ${spacing.sm};

    @media screen and (min-width: ${breakpoint.md}) {
      margin-top: ${elementSize.headerHeight.desktop};
      padding-right: ${spacing.xl};
      padding-left: ${spacing.xl};
    }
  `,
}
