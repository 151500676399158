import { graphql, Link, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useOnClickOutside, useWindowSize } from '../../hooks'
import { breakpoint } from '../../styles/variables'
import linkResolver from '../../utils/linkResolver'
import Icon from '../Icon'
import { Styled } from './style'

const Navbar = ({ activeDoc }) => {
  const ref = useRef()
  const [hiddenMenuOpen, setHiddenMenuOpen] = useState(false)
  useOnClickOutside(ref, () => setHiddenMenuOpen(false))
  const [isMobile, setIsMobile] = useState()
  const windowSize = useWindowSize()

  const { content } = useStaticQuery(
    graphql`
      query {
        content: allPrismicLayout {
          edges {
            node {
              lang
              data {
                navbar_links {
                  link {
                    uid
                    type
                    lang
                    url
                    link_type
                  }
                  title {
                    text
                  }
                }
                product_links {
                  link {
                    uid
                    type
                    lang
                    url
                    link_type
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  let langContent = []
  content.edges.map(item => (langContent[item.node.lang] = item.node.data))
  const localizedData = langContent[activeDoc.lang]

  useEffect(() => {
    windowSize.width < parseInt(breakpoint.lg)
      ? setIsMobile(true)
      : setIsMobile(false)
  }, [windowSize.width])

  const hiddenMenu = (
    <Styled.HiddenMenu
      ref={ref}
      onMouseLeave={() => setHiddenMenuOpen(false)}
      className={`${hiddenMenuOpen ? "visible" : "hidden"} ${
        !isMobile ? "desktop" : ""
      }`}
    >
      {localizedData.product_links.map(item => (
        <li key={uuidv4()}>
          {item.link.uid ? (
            <Link to={linkResolver(item.link)}>
              <Icon name={item.title.text} />
              {item.title.text}
            </Link>
          ) : (
            <p>
              <Icon name={item.title.text} />
              <span>{item.title.text}</span>
            </p>
          )}
        </li>
      ))}
    </Styled.HiddenMenu>
  )

  const navbarItems = (
    <>
      {localizedData.navbar_links.map(item => (
        <React.Fragment key={uuidv4()}>
          {!item.link.uid ? (
            <li ref={ref}>
              <button
                onClick={() => setHiddenMenuOpen(!hiddenMenuOpen)}
                onMouseEnter={() => !isMobile && setHiddenMenuOpen(true)}
                className="products"
              >
                {isMobile ? item.title.text.split(" ").pop() : item.title.text}
              </button>
              {hiddenMenu}
            </li>
          ) : !isMobile && item.link.uid === "home" ? null : (
            <li>
              <Link to={linkResolver(item.link)} activeClassName="active">
                {item.title.text}
              </Link>
            </li>
          )}
        </React.Fragment>
      ))}
    </>
  )

  return (
    <>
      {isMobile ? (
        <Styled.NavbarMobile>
          <Styled.NavbarItemsMobile>{navbarItems}</Styled.NavbarItemsMobile>
        </Styled.NavbarMobile>
      ) : isMobile === false ? (
        <Styled.NavbarDesktop>
          <Styled.NavbarItemsDesktop>{navbarItems}</Styled.NavbarItemsDesktop>
        </Styled.NavbarDesktop>
      ) : null}
    </>
  )
}

Navbar.propTypes = {
  activeDoc: PropTypes.object.isRequired,
}

export default Navbar
