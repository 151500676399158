import styled from "styled-components"
import {
  borderRadius,
  boxShadow,
  breakpoint,
  elementSize,
  font,
  spacing,
} from "../../styles/variables"

export const Styled = {
  NavbarDesktop: styled.nav`
    display: flex;
    align-items: center;
    user-select: none;
  `,
  NavbarItemsDesktop: styled.ul`
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;

      &:not(:last-of-type) {
        margin-right: ${spacing.md};
      }

      a,
      button {
        position: relative;
        padding: ${spacing.xxs};
        font-family: ${font.family.headings};
        font-size: ${font.size.sm};
        font-weight: ${font.weight.semibold};
        color: ${props => props.theme.text};

        &:hover {
          color: ${props => props.theme.accent};
        }

        &.active::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 32px;
          height: 2px;
          background-color: ${props => props.theme.accent};
          transform: translateX(-50%);
        }
      }
    }
  `,
  NavbarMobile: styled.nav`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: ${elementSize.headerHeight.mobile};
    padding-right: ${spacing.sm};
    padding-left: ${spacing.sm};
    background-color: ${props => props.theme.accent};
    box-shadow: ${boxShadow};
  `,
  NavbarItemsMobile: styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 0;
    list-style-type: none;

    a,
    button {
      position: relative;
      padding: 21px 0;
      font-size: ${font.size.xs};
      color: ${props => props.theme.background};
      user-select: none;

      @media screen and (min-width: ${breakpoint.sm}) {
        padding: 21px;
      }

      &:hover {
        color: ${props => props.theme.gray2};
      }

      &.active::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: ${props => props.theme.background};
      }
    }
  `,
  HiddenMenu: styled.ul`
    position: absolute;
    bottom: ${elementSize.headerHeight.mobile};
    left: 0;
    width: 100%;
    margin: 0;
    padding: ${spacing.sm};
    list-style: none;
    background-color: ${props => props.theme.backgroundLight};
    box-shadow: ${boxShadow};

    &.hidden {
      display: none;
    }

    &.desktop {
      top: ${spacing.lg};
      bottom: initial;
      left: 50%;
      width: auto;
      border-radius: ${borderRadius.sm};
      transform: translateX(-50%);
    }

    li {
      &:not(:last-of-type) {
        margin-right: 0;
      }

      a,
      p {
        display: flex;
        align-items: center;
        margin: 0;
        padding: ${spacing.xxs};
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }

      span {
        position: relative;
        padding: 0;
        color: ${props => props.theme.gray5};

        &.teaser {
          margin-left: ${spacing.xxs};
          padding-bottom: ${spacing.xs};
          font-size: ${font.size.xxs};
          font-weight: ${font.weight.bold};
          color: ${props => props.theme.accentHover};
          white-space: nowrap;
        }
      }
    }

    a {
      display: flex;
      line-height: ${spacing.md};
      color: ${props => props.theme.text};

      &:hover {
        color: ${props => props.theme.accentHover};
      }
    }

    svg {
      width: 32px;
      height: 32px;
      margin-right: ${spacing.xxs};
    }
  `,
}
