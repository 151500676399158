import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { useOnClickOutside } from "../../hooks"
import linkResolver from "../../utils/linkResolver"
import { Styled } from "./style"
import Icon from "../Icon"

const LanguageSwitch = ({ activeDoc }) => {
  const ref = useRef()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  useOnClickOutside(ref, () => setDropdownOpen(false))

  if (!activeDoc) return null

  const currentLang = activeDoc ? activeDoc.lang.slice(0, 2) : "en"
  const altLangs = activeDoc ? activeDoc.alternateLanguages : ""

  if (!altLangs || altLangs.length === 0) return null

  return (
    <Styled.Wrapper className={dropdownOpen ? "open" : ""} ref={ref}>
      <Styled.Button onClick={() => setDropdownOpen(!dropdownOpen)}>
        <Icon name="globe" />
        <span>{currentLang.toUpperCase()}</span>
        <Icon name="chevron down" className="dropdown-icon" />
      </Styled.Button>
      {dropdownOpen && (
        <Styled.Dropdown>
          <ul>
            {altLangs.map(altLang => (
              <li key={uuidv4()}>
                <button onClick={() => navigate(linkResolver(altLang))}>
                  {altLang.lang.slice(0, 2).toUpperCase()}
                </button>
              </li>
            ))}
          </ul>
        </Styled.Dropdown>
      )}
    </Styled.Wrapper>
  )
}

LanguageSwitch.propTypes = {
  activeDoc: PropTypes.object.isRequired,
}

export default LanguageSwitch
