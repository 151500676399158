import styled from "styled-components"
import {
  borderRadius,
  boxShadow,
  font,
  spacing,
  transition,
} from "../../styles/variables"

export const Styled = {
  Wrapper: styled.div`
    position: relative;

    &.open {
      .dropdown-icon {
        transform: rotate(-180deg);
      }
    }
  `,
  Button: styled.button`
    display: flex;
    align-items: center;
    padding: ${spacing.xxs};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.bold};
    user-select: none;

    &:hover {
      color: ${props => props.theme.accentHover};

      svg {
        fill: ${props => props.theme.accentHover};
      }
    }

    > :not(:last-child) {
      margin-right: calc(${spacing.xxs} / 2);
    }

    svg {
      fill: ${props => props.theme.text};
      transition: ${transition.fast};
    }
  `,
  Dropdown: styled.div`
    position: absolute;
    top: ${spacing.lg};
    width: 100%;
    padding: ${spacing.xxs};
    text-align: center;
    background-color: ${props => props.theme.backgroundLight};
    border-radius: ${borderRadius.sm};
    box-shadow: ${boxShadow};
    user-select: none;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      > li:not(:first-of-type) {
        margin-top: ${spacing.xxs};
      }
    }

    button {
      width: 100%;
      font-size: ${font.size.xs};
      line-height: inherit;
      border-radius: ${borderRadius.sm};
      transition: ${transition.fast};

      &:hover {
        background-color: ${props => props.theme.gray2};
      }

      &:active {
        background-color: ${props => props.theme.gray2};
      }
    }
  `,
}
