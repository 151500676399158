import styled from "styled-components"
import {
  boxShadow,
  breakpoint,
  elementSize,
  font,
  spacing,
  transition,
} from "../../styles/variables"

export const Styled = {
  HeaderOuter: styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-right: ${spacing.sm};
    padding-left: ${spacing.sm};
    background-color: ${props => props.theme.background};
    transition: ${transition.fast};

    @media screen and (min-width: ${breakpoint.md}) {
      padding-right: ${spacing.xl};
      padding-left: ${spacing.xl};
    }

    &.scrolled {
      background-color: ${props => props.theme.backgroundLight};
      box-shadow: ${boxShadow};
    }
  `,
  HeaderInner: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${elementSize.contentWidth.page};
    height: ${elementSize.headerHeight.mobile};

    @media screen and (min-width: ${breakpoint.md}) {
      height: ${elementSize.headerHeight.desktop};
    }

    @media screen and (min-width: ${parseInt(elementSize.contentWidth.page) +
      parseInt(spacing.xl) * 2}px) {
      margin: 0 auto;
    }
  `,
  SiteTitle: styled.div`
    display: flex;
    align-items: center;
    font-family: ${font.family.headings};
    font-size: ${font.size.sm};
    font-weight: ${font.weight.bold};

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: ${font.size.lg};
    }

    svg {
      height: 2em;
      margin-right: ${spacing.xxs};
    }

    a {
      display: flex;
      align-items: center;
      color: ${props => props.theme.text};

      &:hover {
        color: ${props => props.theme.accent};
      }
    }
  `,
  Actions: styled.div`
    display: flex;
    align-items: center;

    > :not(:last-child) {
      @media screen and (min-width: ${breakpoint.md}) {
        margin-right: ${spacing.md};
      }
    }

    .call-to-action {
      @media screen and (max-width: ${breakpoint.md}) {
        display: none;
      }
    }
  `,
}
