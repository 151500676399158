import React from "react"
import PropTypes from "prop-types"
import IconArrowDown from "../../icons/icon-arrow-down.svg"
import IconArrowRight from "../../icons/icon-arrow-right.svg"
import IconChrevronLeft from "../../icons/icon-chevron-left.svg"
import IconChrevronRight from "../../icons/icon-chevron-right.svg"
import IconChrevronDown from "../../icons/icon-chevron-down.svg"
import IconCross from "../../icons/icon-cross.svg"
import IconGlobe from "../../icons/icon-globe.svg"
import IconMail from "../../icons/icon-mail.svg"
import IconMoon from "../../icons/icon-moon.svg"
import IconSun from "../../icons/icon-sun.svg"
import Logo from "../../icons/logo-asimpletools.svg"
import LogoCircle from "../../icons/logo-asimpletools-circle.svg"
import LogoASimpleMon from "../../icons/logo-asimplemon.svg"
import LogoASimpleFileManager from "../../icons/logo-asimplefilemanager.svg"
import LogoASimpleClientCopy from "../../icons/logo-asimpleclientcopy.svg"
import LogoASimpleMigrationTools from "../../icons/logo-asimplemigrationtools.svg"

const Icon = ({ name, className }) => {
  const components = {
    logo: Logo,
    logocircle: LogoCircle,
    asimplemon: LogoASimpleMon,
    asimplefilemanager: LogoASimpleFileManager,
    asimpleclientcopy: LogoASimpleClientCopy,
    asimplemigrationtools: LogoASimpleMigrationTools,
    arrowdown: IconArrowDown,
    arrowright: IconArrowRight,
    chevronleft: IconChrevronLeft,
    chevronright: IconChrevronRight,
    chevrondown: IconChrevronDown,
    cross: IconCross,
    globe: IconGlobe,
    mail: IconMail,
    moon: IconMoon,
    sun: IconSun,
  }

  if (!name) return null

  const formattedName = name.toString().toLowerCase().replace(/\s/g, "")
  const TagName = components[formattedName]

  return <>{TagName ? <TagName className={className} /> : null}</>
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Icon
