import styled from "styled-components"
import { breakpoint, font, spacing, transition } from "../../styles/variables"

export const Styled = {
  Button: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${spacing.xs};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.medium};
    color: ${props => props.theme.background};
    background-color: ${props => props.theme.accent};
    border-radius: 4px;
    transition: ${transition.fast};
    user-select: none;

    @media screen and (min-width: ${breakpoint.sm}) {
      width: initial;
      padding: ${spacing.xxs} ${spacing.sm};
    }

    &:hover {
      cursor: pointer;
      background-color: ${props => props.theme.accentHover};
    }

    &:active {
      background-color: ${props => props.theme.accentActive};
    }

    &.large {
      padding: ${spacing.xs} ${spacing.md};
    }

    &.light {
      color: ${props => props.theme.text};
      background-color: ${props => props.theme.background};

      &:hover {
        background-color: ${props => props.theme.gray2};
      }

      &:active {
        background-color: ${props => props.theme.gray3};
      }
    }

    &.text {
      padding-right: 0;
      padding-left: 0;
      color: ${props => props.theme.gray5};
      background-color: transparent;

      &:hover {
        color: ${props => props.theme.accentHover};
      }

      &:active {
        color: ${props => props.theme.accentActive};
      }
    }

    &.secondary {
      background-color: ${props => props.theme.text};

      &:hover {
        background-color: ${props => props.theme.gray5};
      }

      &:active {
        background-color: ${props => props.theme.gray4};
      }
    }

    input {
      font: inherit;
      color: inherit;
      background: none;
      border: none;
      appearance: none;

      &:hover {
        cursor: pointer;
      }
    }
  `,
}
