import React from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { RichText } from "prismic-reactjs"
import CustomLink from "../../utils/customLink"
import { Styled } from "./style"
import Card from "../Card"
import ResponsiveImage from "../Responsive Image"
import Button from "../Button"
import Icon from "../Icon"

const Hero = ({ data }) => {
  const heading = data.hero_title?.raw || data.heading || ""
  const subheading = data.hero_subtitle?.raw || data.subheading || ""
  const iconName = data.hero_icon || ""
  const button1Text = data.button_1_text?.text || ""
  const button1Link = data.button_1_link || ""
  const button1Subject =
    data.selected_subject?.text || data.selected_subject_1?.text || ""
  const button2Text = data.button_2_text?.text || ""
  const button2Link = data.button_2_link || ""
  const button2Subject = data.selected_subject_2?.text || ""
  const image = data.hero_image || ""
  const cards = data.cards || ""
  const cardLinkText = data.card_link_text?.text || ""
  const cardStaticText = data.card_static_text?.text || ""

  if (!data) return null

  return (
    <Styled.Hero
      className={`${image ? "hero-image" : ""}${cards ? "hero-cards" : ""}`}
    >
      <Styled.Heading className={image ? "hero-image" : ""}>
        {iconName && (
          <Styled.Icon>
            <Icon name={iconName} />
          </Styled.Icon>
        )}
        {heading && (
          <RichText render={heading || []} serializeHyperlink={CustomLink} />
        )}
        {subheading && (
          <RichText render={subheading || []} serializeHyperlink={CustomLink} />
        )}
        {button1Text && button1Link && (
          <Styled.Buttons>
            <Button
              size="large"
              label={button1Text}
              link={button1Link}
              additionalArgument={button1Subject}
            />
            {button2Text && button2Link && (
              <Button
                type="secondary"
                size="large"
                label={button2Text}
                link={button2Link}
                additionalArgument={button2Subject}
              />
            )}
          </Styled.Buttons>
        )}
      </Styled.Heading>
      {cards && (
        <>
          <Styled.Cards>
            {cards.map(card => (
              <Card
                data={card}
                linkText={cardLinkText}
                staticText={cardStaticText}
                key={uuidv4()}
              />
            ))}
          </Styled.Cards>
          <Styled.ScrollIndicator>
            <Icon name="arrow down" />
          </Styled.ScrollIndicator>
        </>
      )}
      {image && (
        <Styled.ImageWrapper>
          <ResponsiveImage data={image} />
        </Styled.ImageWrapper>
      )}
    </Styled.Hero>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Hero
