import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Styled } from "./style"
import Icon from "../Icon"
import Navbar from "../Navbar"
import LanguageSwitch from "../Language Switch"
import ThemeSwitch from "../Theme Switch"
import Button from "../Button"

const Header = ({ location, activeDoc, darkMode, toggleDarkMode }) => {
  const [scrollTop, setScrollTop] = React.useState(0)

  const { content } = useStaticQuery(
    graphql`
      query {
        content: allPrismicLayout {
          edges {
            node {
              lang
              data {
                display_button
                button_link {
                  uid
                  type
                  lang
                  url
                  link_type
                }
                button_text {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  let langContent = []
  content.edges.map(item => (langContent[item.node.lang] = item.node.data))
  const localizedData = langContent[activeDoc.lang]

  React.useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  if (!activeDoc) return null

  return (
    <Styled.HeaderOuter className={scrollTop > 80 ? "scrolled" : ""}>
      <Styled.HeaderInner>
        <Styled.SiteTitle>
          {location === "Home" ? (
            <>
              <Icon name="Logo Circle" />
              <span>aSimpleTools</span>
            </>
          ) : (
            <Link
              to={
                activeDoc.lang === "en-us"
                  ? "/"
                  : `/${activeDoc.lang.slice(0, 2)}`
              }
            >
              <Icon name="Logo Circle" />
              <span>aSimpleTools</span>
            </Link>
          )}
        </Styled.SiteTitle>
        <Styled.Actions>
          <Navbar activeDoc={activeDoc} />
          <LanguageSwitch activeDoc={activeDoc} />
          <ThemeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          {localizedData.display_button &&
          localizedData.button_link.url !== window.location.pathname ? (
            <Button
              label={localizedData.button_text.text}
              link={localizedData.button_link}
              ariaLabel="Call to Action"
              className="call-to-action"
            />
          ) : null}
        </Styled.Actions>
      </Styled.HeaderInner>
    </Styled.HeaderOuter>
  )
}

Header.propTypes = {
  location: PropTypes.string,
  activeDoc: PropTypes.object.isRequired,
  darkMode: PropTypes.bool,
  toggleDarkMode: PropTypes.func,
}

export default Header
