import React from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { RichText } from "prismic-reactjs"
import CustomLink from "../../utils/customLink"
import { Styled } from "./style"
import Button from "../Button"
import ResponsiveImage from "../Responsive Image"
import Card from "../Card"
import Accordion from "../Accordion"
import ImageGallery from "../Image Gallery"

const Section = ({ data }) => {
  if (!data) return null

  const type = data.primary?.section_type || data.slice_type || undefined
  const heading = data.primary?.heading?.raw || ""
  const subheading = data.primary?.subheading?.raw || ""
  const buttonText =
    data.primary?.button_text?.text || data.button_text?.text || ""
  const buttonLink = data.primary?.button_link || data.button_link || ""
  const content =
    data.primary?.text?.raw ||
    data.items ||
    data.primary?.content?.raw ||
    data.content?.raw ||
    ""
  const images = data.primary?.image || data.items || ""
  const faqs = data.items || ""
  const imagePosition = data.primary?.image_position || ""
  const textBelow = data.primary?.text_below || ""
  const typeContainsNumber = /\d/.test(type)

  const SectionTitle = alignment => (
    <>
      {Object.keys(heading).length > 0 && (
        <Styled.Title className={alignment || ""}>
          {heading &&
            (typeof heading === "object" ? (
              <RichText
                render={heading || []}
                serializeHyperlink={CustomLink}
              />
            ) : (
              <h2>{heading}</h2>
            ))}
          {subheading &&
            (typeof subheading === "object" ? (
              <RichText
                render={subheading || []}
                serializeHyperlink={CustomLink}
              />
            ) : (
              <h3>{subheading}</h3>
            ))}
        </Styled.Title>
      )}
    </>
  )

  const SectionButton = () => (
    <>
      {buttonText && buttonLink ? (
        <Styled.Button className={type !== "section_cta" && "center"}>
          <Button
            label={buttonText}
            link={buttonLink}
            type={type === "section_cta" ? "light" : ""}
            additionalArgument={data.primary?.selected_subject?.text || ""}
          />
        </Styled.Button>
      ) : null}
    </>
  )

  switch (true) {
    case type === undefined && typeof content === "object":
      return (
        <Styled.Wrapper>
          <RichText render={content || []} serializeHyperlink={CustomLink} />
          {SectionButton()}
        </Styled.Wrapper>
      )
    case typeContainsNumber:
      return (
        <Styled.Wrapper>
          <Styled.Content>
            {SectionTitle("center")}
            <Styled.Row
              className={typeContainsNumber ? `row-grid-${parseInt(type)}` : ""}
            >
              {content.map(item => (
                <Styled.Column key={uuidv4()}>
                  <RichText
                    render={item.column?.raw || []}
                    serializeHyperlink={CustomLink}
                  />
                </Styled.Column>
              ))}
            </Styled.Row>
            {SectionButton()}
          </Styled.Content>
        </Styled.Wrapper>
      )
    case type === "section_image":
      return (
        <Styled.Wrapper className="section-image">
          <Styled.Content>
            <Styled.Row className="row-image">
              {images && imagePosition === "Left" && (
                <Styled.Column className="col-image">
                  <ResponsiveImage data={images} />
                </Styled.Column>
              )}
              <Styled.Column>
                {SectionTitle()}
                <RichText
                  render={content || []}
                  serializeHyperlink={CustomLink}
                />
                {SectionButton()}
              </Styled.Column>
              {images && imagePosition === "Right" && (
                <Styled.Column className="col-image col-image-right">
                  <ResponsiveImage data={images} />
                </Styled.Column>
              )}
            </Styled.Row>
          </Styled.Content>
        </Styled.Wrapper>
      )
    case type === "section_cards":
      return (
        <Styled.Wrapper className="section-cards">
          <Styled.Content>
            {SectionTitle("center")}
            <Styled.Cards>
              {data.items?.map(card => (
                <Card data={card} key={uuidv4()} />
              ))}
            </Styled.Cards>
          </Styled.Content>
        </Styled.Wrapper>
      )
    case type === "section_cta":
      return (
        <Styled.Wrapper className="section-cta">
          <Styled.Content className="content-cta">
            <Styled.Column className="col-cta">
              {SectionTitle()}
              <RichText
                render={content || []}
                serializeHyperlink={CustomLink}
              />
              {SectionButton()}
            </Styled.Column>
            {Object.keys(images).length > 0 && (
              <Styled.Column className="col-cta">
                <Styled.ImageWrapper
                  className={`image-wrapper-${
                    Object.keys(images).length
                  }-child`}
                >
                  {images.map(item => (
                    <ResponsiveImage key={uuidv4()} data={item.image} />
                  ))}
                </Styled.ImageWrapper>
              </Styled.Column>
            )}
          </Styled.Content>
        </Styled.Wrapper>
      )
    case type === "section_faq":
      return (
        <Styled.Wrapper>
          <Styled.Content>
            {SectionTitle("center")}
            {!textBelow && (
              <RichText
                render={content || []}
                serializeHyperlink={CustomLink}
              />
            )}
            <Styled.Accordions>
              {faqs.map(item => (
                <Accordion
                  title={item.question?.text}
                  content={item.answer?.raw}
                  key={uuidv4()}
                ></Accordion>
              ))}
            </Styled.Accordions>
            {textBelow && (
              <Styled.AccordionText>
                <RichText
                  render={content || []}
                  serializeHyperlink={CustomLink}
                />
              </Styled.AccordionText>
            )}
            {SectionButton()}
          </Styled.Content>
        </Styled.Wrapper>
      )
    case type === "image_gallery":
      return (
        <Styled.Wrapper>
          <Styled.Content>
            {SectionTitle("center")}
            <ImageGallery data={content} />
          </Styled.Content>
        </Styled.Wrapper>
      )
    default:
      return <></>
  }
}

Section.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Section
