import { graphql, Link, useStaticQuery } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import CustomLink from '../../utils/customLink'
import linkResolver from '../../utils/linkResolver'
import Icon from '../Icon'
import { Styled } from './style'

const Footer = ({ activeDoc }) => {
  const { content } = useStaticQuery(
    graphql`
      query {
        content: allPrismicLayout {
          edges {
            node {
              lang
              data {
                company_description {
                  text
                }
                copyright {
                  raw
                }
                trademark {
                  raw
                }
                mail_address {
                  text
                }
                body {
                  ... on PrismicLayoutBodyText {
                    items {
                      link_destination {
                        uid
                        type
                        lang
                        url
                        link_type
                      }
                      link_title {
                        text
                      }
                    }
                    primary {
                      column_title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  let langContent = []
  content.edges.map(item => (langContent[item.node.lang] = item.node.data))
  const localizedData = langContent[activeDoc.lang]
  const copyright = localizedData.copyright?.raw
  const trademark = localizedData.trademark?.raw

  if (!activeDoc) return null

  return (
    <Styled.FooterOuter>
      <Styled.FooterTop>
        <Styled.InfoColumn>
          <Styled.LogoFull>
            <Icon name="Logo" />
            <span>aSimpleTools</span>
          </Styled.LogoFull>
          <p>{localizedData.company_description?.text}</p>
          <Styled.ContactInfo
            href={`mailto:${localizedData.mail_address?.text}`}
          >
            <Icon name="Mail" />
            <span>{localizedData.mail_address?.text}</span>
          </Styled.ContactInfo>
        </Styled.InfoColumn>
        {localizedData.body?.map(item => (
          <Styled.LinkColumn key={uuidv4()}>
            <p>{item.primary.column_title.text}</p>
            <ul>
              {item.items.map(link => (
                <li key={uuidv4()}>
                  {link.link_destination.uid !== null ? (
                    link.link_destination.link_type === "Document" ? (
                      <Link to={linkResolver(link.link_destination)}>
                        {link.link_title.text}
                      </Link>
                    ) : (
                      <a href={link.link_destination.url}>
                        {link.link_title.text}
                      </a>
                    )
                  ) : null}
                </li>
              ))}
            </ul>
          </Styled.LinkColumn>
        ))}
      </Styled.FooterTop>
      <Styled.FooterBottom>
        <Styled.Copyright>
          <span>© {new Date().getFullYear()} </span>
          {copyright ? (
            <RichText
              render={copyright || []}
              serializeHyperlink={CustomLink}
            />
          ) : (
            <span>aSimpleTools Inc.</span>
          )}
        </Styled.Copyright>
        {trademark && (
          <Styled.Trademark>
            <RichText
              render={trademark || []}
              serializeHyperlink={CustomLink}
            />
          </Styled.Trademark>
        )}
      </Styled.FooterBottom>
    </Styled.FooterOuter>
  )
}

Footer.propTypes = {
  activeDoc: PropTypes.object.isRequired,
}

export default Footer
