import styled from "styled-components"
import {
  borderRadius,
  boxShadow,
  breakpoint,
  color,
  spacing,
  transition,
} from "../../styles/variables"

const galleryHeight = 300
const galleryWidth = 533

export const Styled = {
  Wrapper: styled.div`
    position: relative;
    height: ${galleryHeight + 64}px;
    user-select: none;
  `,
  ImageContainerWrapper: styled.div`
    position: relative;
    left: 50%;
    width: 100%;
    height: ${galleryHeight + 64}px;
    overflow: hidden;
    transform: translateX(-50%);

    @media screen and (min-width: ${breakpoint.sm}) {
      width: 130%;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 20%;
      height: 100%;
    }

    &.left-gradient:before {
      top: 0;
      left: -2%;
      background: linear-gradient(
        to right,
        ${props => props.theme.textInverted} 0%,
        transparent 100%
      );
    }

    &.right-gradient:after {
      top: 0;
      right: -2%;
      background: linear-gradient(
        to left,
        ${props => props.theme.textInverted} 0%,
        transparent 100%
      );
    }
  `,
  ImageContainer: styled.div`
    position: absolute;
    top: ${spacing.md};
    left: 50%;
    display: flex;
    transition: ${transition.medium};
    transform: translateX(-50%);

    > :not(:last-child) {
      margin-right: ${spacing.md};
    }
  `,
  Image: styled.div`
    position: relative;

    &:hover {
      cursor: pointer;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 18 18' fill='none'><path d='M17 17H13M1 5V1V5ZM1 1H5H1ZM1 1L6 6L1 1ZM17 5V1V5ZM17 1H13H17ZM17 1L12 6L17 1ZM1 13V17V13ZM1 17H5H1ZM1 17L6 12L1 17ZM17 17L12 12L17 17ZM17 17V13V17Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      border-radius: ${borderRadius.sm};
      opacity: 0;
      transition: ${transition.medium};
    }

    img {
      max-width: ${galleryWidth}px;
      height: ${galleryHeight}px;
      border-radius: ${borderRadius.sm};
      box-shadow: ${boxShadow};
    }
  `,
  LightboxWrapper: styled.div`
    padding: ${spacing.xxl};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    user-select: none;
  `,
  Lightbox: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  ImageFull: styled.div`
    img {
      width: 100%;
      height: auto;
      border-radius: ${borderRadius.sm};
      pointer-events: none;
    }
  `,
  Description: styled.div`
    color: ${color.white};
    text-align: center;
  `,
  NavigationButton: styled.div`
    position: absolute;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${spacing.xxl};
    height: ${spacing.xxl};
    text-align: center;
    transform: translateY(-50%);

    svg {
      width: ${spacing.lg};
      height: ${spacing.lg};
      fill: ${color.white};
      transition: ${transition.fast};
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: ${color.primary};
      }
    }

    &.gallery {
      z-index: 1;
      width: auto;

      svg {
        width: ${spacing.md};
        height: ${spacing.md};
        fill: ${props => props.theme.text};
      }

      &:hover {
        svg {
          fill: ${color.primary};
        }
      }
    }

    &.prev {
      top: 50%;
      left: 0;
    }

    &.next {
      top: 50%;
      right: 0;
    }

    &.close {
      top: ${spacing.xxs};
      right: 0;
      margin-top: ${spacing.sm};
    }
  `,
}
