import React, { useState } from "react"
import PropTypes from "prop-types"
import { Styled } from "./style"
import { RichText } from "prismic-reactjs"
import CustomLink from "../../utils/customLink"
import Icon from "./../Icon"

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!title || !content) return null

  return (
    <Styled.Wrapper>
      <Styled.Title
        className={isOpen ? "open" : ""}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="chevron down" />
        {title}
      </Styled.Title>
      {isOpen && (
        <Styled.Content>
          <RichText render={content || []} serializeHyperlink={CustomLink} />
        </Styled.Content>
      )}
    </Styled.Wrapper>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
}

export default Accordion
